@use 'sass:math';
$colors: #00FFFF, #00DCFF, #55B4FC, #8A8BD4;
$max: length($colors);
$dash: 90;
$dash-gap: 1;
$dash-space: $dash * ($max - 1) + $dash-gap * $max;
$time: 10s;
$time-step: math.div($time,$max);
.hero-text {
  fill: rgba(0, 255, 255, 0.015);
  stroke-width: 3;
  stroke-linejoin: round;
  stroke-dasharray: $dash $dash-space;
  stroke-dashoffset: 0;
  -webkit-animation: stroke $time infinite linear;
  animation: stroke $time infinite linear;
  @for $item from 1 through $max {
    &:nth-child(#{$max}n + #{$item}) {
      $color: nth($colors, $item);
      stroke: $color;
      -webkit-animation-delay: -($time-step * $item);
      animation-delay: -($time-step * $item);
    }
  }
}
@-webkit-keyframes stroke {
  100% {
    stroke-dashoffset: - ($dash + $dash-gap) * $max;
  }
}
@keyframes stroke {
  100% {
    stroke-dashoffset: - ($dash + $dash-gap) * $max;
  }
}

.hero-desc {
  text-transform: none;
  color: #a0bdbd;
}