.work-ex {
    grid-column: 2/14;
    padding: 6rem;

    &-box__wrapper {
        display: grid;grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
        grid-gap: 4rem;
        color: $black;
        margin-top: 6rem;
    }

    &-box {
        padding: 2rem;
        height: 48rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;

        @media screen and (max-width: 1300px) {
          height: 64rem;  
        }

        &:hover {
            border: solid 2px $white;
            color: $white;
        }
    }
    .work-ex-box:nth-child(1) {
        background: linear-gradient(to bottom, #f3daab, #cad3fe);
    }
    .work-ex-box:nth-child(2) {
        background: linear-gradient(to bottom, #a6f8f1, #f2f3be);
    }
    .work-ex-box:nth-child(3) {
        background: linear-gradient(to bottom, #f2f7c9, #ffd5ce);
    }
    .work-ex-box:nth-child(4) {
        background: linear-gradient(to bottom, #f8bfbf, #eafeca);
    }
    .work-ex-box:nth-child(5) {
        background: linear-gradient(to bottom, #dfc2e7, #bef3d2);
    }
    .work-ex-box:nth-child(6) {
        background: linear-gradient(to bottom, #f7c9e2, #e9ceff);
    }

    &-box__content {
        text-align: center;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        top: 2rem;
        left: 0%;

        &-desc {
            text-align: left;
            top: 13rem;
            line-height: 1.8rem;
            letter-spacing: 0.0px;
            margin: 2rem;

            li {
                font-size: 1.4rem;
                font-weight: lighter;
            }
        }
    }
}

// hover state
.work-ex-box:nth-child(1), .work-ex-box:nth-child(2), .work-ex-box:nth-child(3), .work-ex-box:nth-child(4), .work-ex-box:nth-child(5), .work-ex-box:nth-child(6) {
    background: linear-gradient(to bottom, #F3CBAB, #dfbcbe);
    &:after {
        content: '';
        position: absolute;
        width: 103%;
        height: 101%;
        top: 1rem;
        right: 0;
        z-index: -1;
    }
    &:hover {
        background: none;
        background-color: $black;
    }
    &:hover  img {
        filter: invert(1);
    }
    &:hover:after {
        outline: 2px solid $white;
    }
}