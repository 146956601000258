.skills {
    grid-column: 2/14;
    padding: 6rem;

    &-box__wrapper {
        display: grid;grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
        grid-gap: 4rem;
        margin-top: 6rem;
    }

    &-box {

        &-skill {
            padding: 1.5rem 2rem 1rem 2rem;
            border: 1px solid $secondary-color;
            border-bottom: 4px solid $primary-color;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            height: 6rem;
            
            a {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                text-decoration: none;
                width: 100%;
            }
            
            h4 {
                display: flex;
                flex-direction: column;
                justify-content: center;
                font-size: 2rem;
                text-transform: capitalize;
                text-decoration: none;
                color: $secondary-color;
                font-family: $tertiary-font;
                transition: color .3s ease-in-out;
            }
    
            img {
                height: 2rem;
            }

            &:hover {
                // cursor: pointer;
                h4 {
                    color: white;
                }
        
                img {
                    height: 2rem;
                    filter: invert(1) saturate(100);
                }
            }
        }
    }
}