.contact {
    grid-column: 2/14;
    padding: 6rem;

    &-box__wrapper {
        margin-top: 8rem;
        display: grid;grid-template-columns: repeat(auto-fit, minmax(35rem, 1fr));
        grid-gap: 4rem;
    }

    &-box {
        margin: 0.5rem;
        a {
            font-size: 2.75rem;
            text-decoration: none;
            color: $white;
            font-family: $tertiary-font;
            transition: color .2s ease-in-out;
            padding-top: 2rem;

            &:hover {
                color: $primary-color;
            }
        }

        .cta {
            // position: relative;
            color: black;
            display: block;
            text-align: center;
            max-width: 35rem;
        }
    }
}