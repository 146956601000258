@font-face {
  font-family: AntonioBold;
  src: url("antonio-bold-webfont.b304df70.woff") format("woff"), url("antonio-bold-webfont.7709eb14.ttf") format("truetype"), url("antonio-bold-webfont.b1808263.svg#antoniobold") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: AntonioLight;
  src: url("antonio-light-webfont.bdff29aa.woff") format("woff"), url("antonio-light-webfont.ebaf20a6.ttf") format("truetype");
  font-weight: bolder;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: AntonioRegular;
  src: url("antonio-regular-webfont.c3f4d30a.woff") format("woff"), url("antonio-regular-webfont.e8be7149.ttf") format("truetype");
  font-weight: bolder;
  font-style: normal;
  font-display: swap;
}

*, :before, :after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

html {
  box-sizing: border-box;
  letter-spacing: .1em;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  width: 100%;
  height: 100%;
  font-size: 62.5%;
}

@media only screen and (max-width: 62.5em) {
  html {
    font-size: 56.25%;
  }
}

@media only screen and (max-width: 56.25em) {
  html {
    font-size: 54%;
  }
}

@media only screen and (max-width: 37.5em) {
  html {
    font-size: 51%;
  }
}

@media only screen and (min-width: 112.5em) {
  html {
    font-size: 75%;
  }
}

body {
  color: #fff;
  cursor: none;
  width: 100%;
  height: 100%;
  background-color: #000;
  font-family: AntonioRegular, sans-serif;
  font-size: 1.6rem;
  position: relative;
  overflow-x: hidden;
}

@media only screen and (min-width: 20em) {
  body {
    max-width: 100%;
    overflow-x: hidden;
  }
}

main {
  grid-template-columns: 1fr repeat(12, minmax(min-content, 10rem)) 1fr;
  display: grid;
}

.noScroll {
  overflow-y: hidden;
}

.smooth-scroll-wrapper {
  width: 100vw;
  height: 100%;
  position: relative;
}

section {
  will-change: transform;
  transition: transform .25s;
}

.splash {
  width: 100vw;
  height: 100vh;
  z-index: 20;
  background-color: #000;
  position: absolute;
  top: 0;
  left: 0;
}

.splash .logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.splash .logo path:nth-child(1) {
  stroke-width: 3px;
  stroke-dasharray: 425;
  stroke-dashoffset: 425px;
  animation: 1s .2s forwards line-anim;
}

.splash svg {
  animation: 2.4s ease-in .1s forwards fill;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@keyframes line-anim {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes fill {
  from {
    fill: #0000;
  }

  to {
    fill: #0ff;
  }
}

.cursor {
  width: 2rem;
  height: 2rem;
  pointer-events: none;
  transform-origin: 100% 100%;
  border: 2.5px solid #fff;
  border-radius: 50%;
  transition: background .3s, transform .3s;
  position: absolute;
  top: -10rem;
  left: -10rem;
  transform: translate(-50%, -50%);
}

@media only screen and (max-width: 62.5em) {
  .cursor {
    opacity: 0;
  }
}

.heading-2 {
  letter-spacing: -1px;
  text-transform: capitalize;
  font-size: 4rem;
}

.heading-3 {
  text-transform: capitalize;
  letter-spacing: -.5px;
  font-family: AntonioLight, sans-serif;
  font-size: 4rem;
  line-height: 4rem;
}

.heading-4 {
  text-transform: uppercase;
  letter-spacing: .2rem;
  line-height: 2.5rem;
}

.heading-5 {
  text-transform: uppercase;
  letter-spacing: .2rem;
  font-size: 1.3rem;
  line-height: 1.7rem;
}

.paragraph {
  color: #797575;
  font-size: 1.5rem;
}

.cta {
  color: #000;
  text-transform: uppercase;
  letter-spacing: 3px;
  cursor: pointer;
  background-color: #0ff;
  border: 1.5px solid #0000;
  border-radius: 10rem;
  padding: 1.5rem 4rem;
  font-family: AntonioBold, sans-serif;
  font-size: 1.4rem;
  text-decoration: none;
  transition: all .3s ease-in-out;
}

.cta:hover {
  color: #fff;
  background-color: #0000;
  border: 1.5px solid #fff;
}

.pri-color {
  color: #0ff;
}

.sec-color {
  color: #797575;
}

.mb-l {
  margin-bottom: 5rem;
}

.mb-m {
  margin-bottom: 3rem;
}

.mb-s {
  margin-bottom: 1.3rem;
}

.mt-s {
  margin-top: 1rem;
}

.contact {
  grid-column: 2 / 14;
  padding: 6rem;
}

.contact-box__wrapper {
  grid-gap: 4rem;
  grid-template-columns: repeat(auto-fit, minmax(35rem, 1fr));
  margin-top: 8rem;
  display: grid;
}

.contact-box {
  margin: .5rem;
}

.contact-box a {
  color: #fff;
  padding-top: 2rem;
  font-family: AntonioLight, sans-serif;
  font-size: 2.75rem;
  text-decoration: none;
  transition: color .2s ease-in-out;
}

.contact-box a:hover {
  color: #0ff;
}

.contact-box .cta {
  color: #000;
  text-align: center;
  max-width: 35rem;
  display: block;
}

.footer {
  color: #797575;
  justify-content: space-between;
  padding: 4rem;
  display: flex;
}

.nav-container {
  padding: 4rem;
  position: absolute;
  top: 0;
  left: 0;
}

.nav-container .nav-icon {
  z-index: 10;
  width: 6rem;
  height: 6rem;
  cursor: pointer;
  background-color: #000;
  border-radius: 50%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  top: 2.5rem;
  right: 4rem;
}

.nav-container .nav-icon .bar1, .nav-container .nav-icon .bar2, .nav-container .nav-icon .bar3 {
  width: 20px;
  height: 2px;
  background-color: #fff;
  border-radius: 1rem;
  margin: 4px 0;
  transition: all .4s;
}

.nav-container .nav-icon .bar2 {
  width: 30px;
  transform-origin: 100%;
}

.nav-container .nav-main {
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
}

.nav-container .nav-main .box-wrapper {
  z-index: 1;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.nav-container .nav-main .box-wrapper .box {
  width: 25vw;
  height: 0;
  background-color: #0ff;
}

.nav-container .nav-main .mobile-logo {
  z-index: 2;
  filter: brightness(1%);
  opacity: 0;
  position: absolute;
  top: 4rem;
  left: 4rem;
}

.nav-container .nav-main__content {
  opacity: 0;
  visibility: hidden;
  width: 95vw;
  z-index: 2;
  grid-gap: 4rem;
  color: #000;
  grid-template-columns: repeat(auto-fit, minmax(35rem, 1fr));
  padding: 4rem;
  display: grid;
  position: absolute;
  top: 15rem;
  left: 4rem;
}

.nav-container .nav-main__content--box {
  min-height: 25rem;
  flex-direction: column;
  display: flex;
}

.nav-container .nav-main__content--box .social-links {
  flex-direction: column;
  justify-content: flex-start;
  display: flex;
}

.nav-container .nav-main__content--box .social-links--box {
  align-items: center;
  display: flex;
}

.nav-container .nav-main__content--box .social-links--box img {
  height: 1.7rem;
  filter: brightness(1%);
  margin-right: 2rem;
}

.nav-container .nav-main__content--box .social-links--box:nth-child(2) {
  margin-top: 1rem;
}

.nav-container .nav-main__content--box .list {
  justify-self: flex-start;
}

.nav-container .nav-main__content--box .list__item {
  list-style: none;
}

.nav-container .nav-main__content--box .list__item:not(:last-child) {
  margin-bottom: 3.5rem;
}

.nav-container .nav-main__content--box .list__item a {
  text-transform: uppercase;
  color: #000;
  letter-spacing: -1px;
  font-family: AntonioLight, sans-serif;
  font-size: 3rem;
  text-decoration: none;
  transition: all .2s ease-in-out;
}

.nav-container .nav-main__content--box .list__item a:hover {
  letter-spacing: 3px;
}

.nav-container .nav-sub {
  position: absolute;
}

.header {
  min-height: 80rem;
  position: relative;
  overflow: hidden;
}

@media only screen and (max-width: 37.5em) {
  .header {
    min-height: 70rem;
  }
}

.header:after {
  content: "";
  width: 100vw;
  height: 100%;
  z-index: -1;
  background: url("home-bg.d07778c7.svg") 100% 0 no-repeat;
  position: absolute;
  top: 0;
  right: 0;
}

@media only screen and (max-width: 56.25em) {
  .header:after {
    height: 150%;
    background-position: 85%;
    bottom: 0;
    left: 0;
    transform: rotate(90deg);
  }
}

.header .hero {
  position: absolute;
  top: 50%;
  left: 10%;
  transform: translateY(-70%);
}

.header .hero #top-heading {
  font-size: 2.8rem;
  font-weight: lighter;
  line-height: 4rem;
}

.header .hero p {
  letter-spacing: .015rem;
  font-size: 1.5rem;
  line-height: 1.7rem;
}

@media only screen and (max-width: 56.25em) {
  .header .hero {
    top: 55%;
  }
}

.text-container {
  width: 25rem;
  height: 15rem;
  position: relative;
  overflow: hidden;
}

.text-container .hero-text {
  font: 10rem AntonioBold;
}

.text-container video {
  height: 100%;
  position: absolute;
}

.text-container svg {
  position: absolute;
}

.text-container span {
  color: #fff0;
  text-transform: lowercase;
  font-family: AntonioBold, sans-serif;
  font-size: 8rem;
  position: absolute;
  top: 0;
  left: 1.5rem;
}

.nav-anim .bar1 {
  transform: rotate(-45deg)translate(-6px, 7px);
}

.nav-anim .bar2 {
  opacity: 0;
  transform: translateX(200%);
}

.nav-anim .bar3 {
  transform: rotate(45deg)translate(-7px, -8px);
}

.project {
  grid-column: 2 / 14;
  padding: 6rem;
}

.project-box__wrapper {
  grid-gap: 4rem;
  color: #000;
  grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
  margin-top: 6rem;
  display: grid;
}

.project-box {
  max-height: 35rem;
  height: 30rem;
  flex-direction: column;
  justify-content: space-between;
  padding: 2rem;
  display: flex;
  position: relative;
}

.project-box:hover {
  color: #fff;
  border: 2px solid #fff;
}

.project .project-box:nth-child(1) {
  background: linear-gradient(#f3cbab, #feedca);
}

.project .project-box:nth-child(2) {
  background: linear-gradient(#b1e5f9, #f4d2fe);
}

.project .project-box:nth-child(3) {
  background: linear-gradient(#dbb4f3, #efb7d7);
}

.project .project-box:nth-child(4) {
  background: linear-gradient(#efa971, #e4cafe);
}

.project .project-box:nth-child(5) {
  background: linear-gradient(to right, #b1e5f9, #f4d2fe);
}

.project .project-box:nth-child(6) {
  background: linear-gradient(#f3cbab, #feedca);
}

.project .project-box:nth-child(7) {
  background: linear-gradient(#b1e5f9, #f4d2fe);
}

.project .project-box:nth-child(8) {
  background: linear-gradient(#dbb4f3, #efb7d7);
}

.project .project-box:nth-child(9) {
  background: linear-gradient(#efa971, #e4cafe);
}

.project .project-box:nth-child(10) {
  background: linear-gradient(to right, #b1e5f9, #f4d2fe);
}

.project-box__link {
  width: min-content;
  justify-content: space-between;
  align-self: flex-end;
  align-items: center;
  padding-bottom: 2rem;
  display: flex;
}

@media only screen and (max-width: 56.25em) {
  .project-box__link {
    width: 30%;
  }
}

.project-box__content {
  text-align: center;
  align-self: center;
}

.project-box__content p {
  letter-spacing: 0;
  line-height: 2rem;
}

.project-box:nth-child(1), .project-box:nth-child(2), .project-box:nth-child(3), .project-box:nth-child(4), .project-box:nth-child(5), .project-box:nth-child(6), .project-box:nth-child(7), .project-box:nth-child(8), .project-box:nth-child(9), .project-box:nth-child(10) {
  background: linear-gradient(#f3cbab, #feedca);
}

.project-box:nth-child(1):after, .project-box:nth-child(2):after, .project-box:nth-child(3):after, .project-box:nth-child(4):after, .project-box:nth-child(5):after, .project-box:nth-child(6):after, .project-box:nth-child(7):after, .project-box:nth-child(8):after, .project-box:nth-child(9):after, .project-box:nth-child(10):after {
  content: "";
  width: 103%;
  height: 101%;
  z-index: -1;
  position: absolute;
  top: 1rem;
  right: 0;
}

.project-box:nth-child(1):hover, .project-box:nth-child(2):hover, .project-box:nth-child(3):hover, .project-box:nth-child(4):hover, .project-box:nth-child(5):hover, .project-box:nth-child(6):hover, .project-box:nth-child(7):hover, .project-box:nth-child(8):hover, .project-box:nth-child(9):hover, .project-box:nth-child(10):hover {
  background: #000;
}

.project-box:nth-child(1):hover img, .project-box:nth-child(2):hover img, .project-box:nth-child(3):hover img, .project-box:nth-child(4):hover img, .project-box:nth-child(5):hover img, .project-box:nth-child(6):hover img, .project-box:nth-child(7):hover img, .project-box:nth-child(8):hover img, .project-box:nth-child(9):hover img, .project-box:nth-child(10):hover img {
  filter: invert();
}

.project-box:nth-child(1):hover:after, .project-box:nth-child(2):hover:after, .project-box:nth-child(3):hover:after, .project-box:nth-child(4):hover:after, .project-box:nth-child(5):hover:after, .project-box:nth-child(6):hover:after, .project-box:nth-child(7):hover:after, .project-box:nth-child(8):hover:after, .project-box:nth-child(9):hover:after, .project-box:nth-child(10):hover:after {
  outline: 2px solid #fff;
}

.web {
  grid-column: 2 / 14;
  padding: 6rem;
}

.web-box__wrapper {
  grid-gap: 4rem;
  grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
  margin-top: 6rem;
  display: grid;
}

.web-box {
  border: 1px solid #797575;
  border-bottom: 4px solid #0ff;
  padding: 2rem 2rem .5rem;
}

.web-box a {
  text-transform: capitalize;
  color: #797575;
  flex-direction: column;
  font-family: AntonioLight, sans-serif;
  font-size: 2rem;
  text-decoration: none;
  transition: color .3s ease-in-out;
  display: flex;
}

.web-box a img {
  height: 2rem;
  align-self: flex-end;
}

.web-box a:hover {
  color: #fff;
}

.hero-text {
  fill: #00ffff04;
  stroke-width: 3px;
  stroke-linejoin: round;
  stroke-dasharray: 90 274;
  stroke-dashoffset: 0;
  animation: 10s linear infinite stroke;
}

.hero-text:nth-child(4n+1) {
  stroke: #0ff;
  animation-delay: -2.5s;
}

.hero-text:nth-child(4n+2) {
  stroke: #00dcff;
  animation-delay: -5s;
}

.hero-text:nth-child(4n+3) {
  stroke: #55b4fc;
  animation-delay: -7.5s;
}

.hero-text:nth-child(4n+4) {
  stroke: #8a8bd4;
  animation-delay: -10s;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: -364px;
  }
}

.hero-desc {
  text-transform: none;
  color: #a0bdbd;
}

.work-ex {
  grid-column: 2 / 14;
  padding: 6rem;
}

.work-ex-box__wrapper {
  grid-gap: 4rem;
  color: #000;
  grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
  margin-top: 6rem;
  display: grid;
}

.work-ex-box {
  height: 48rem;
  flex-direction: column;
  justify-content: space-between;
  padding: 2rem;
  display: flex;
  position: relative;
}

@media screen and (max-width: 1300px) {
  .work-ex-box {
    height: 64rem;
  }
}

.work-ex-box:hover {
  color: #fff;
  border: 2px solid #fff;
}

.work-ex .work-ex-box:nth-child(1) {
  background: linear-gradient(#f3daab, #cad3fe);
}

.work-ex .work-ex-box:nth-child(2) {
  background: linear-gradient(#a6f8f1, #f2f3be);
}

.work-ex .work-ex-box:nth-child(3) {
  background: linear-gradient(#f2f7c9, #ffd5ce);
}

.work-ex .work-ex-box:nth-child(4) {
  background: linear-gradient(#f8bfbf, #eafeca);
}

.work-ex .work-ex-box:nth-child(5) {
  background: linear-gradient(#dfc2e7, #bef3d2);
}

.work-ex .work-ex-box:nth-child(6) {
  background: linear-gradient(#f7c9e2, #e9ceff);
}

.work-ex-box__content {
  text-align: center;
  flex-direction: column;
  justify-content: space-evenly;
  display: flex;
  position: relative;
  top: 2rem;
  left: 0%;
}

.work-ex-box__content-desc {
  text-align: left;
  letter-spacing: 0;
  margin: 2rem;
  line-height: 1.8rem;
  top: 13rem;
}

.work-ex-box__content-desc li {
  font-size: 1.4rem;
  font-weight: lighter;
}

.work-ex-box:nth-child(1), .work-ex-box:nth-child(2), .work-ex-box:nth-child(3), .work-ex-box:nth-child(4), .work-ex-box:nth-child(5), .work-ex-box:nth-child(6) {
  background: linear-gradient(#f3cbab, #dfbcbe);
}

.work-ex-box:nth-child(1):after, .work-ex-box:nth-child(2):after, .work-ex-box:nth-child(3):after, .work-ex-box:nth-child(4):after, .work-ex-box:nth-child(5):after, .work-ex-box:nth-child(6):after {
  content: "";
  width: 103%;
  height: 101%;
  z-index: -1;
  position: absolute;
  top: 1rem;
  right: 0;
}

.work-ex-box:nth-child(1):hover, .work-ex-box:nth-child(2):hover, .work-ex-box:nth-child(3):hover, .work-ex-box:nth-child(4):hover, .work-ex-box:nth-child(5):hover, .work-ex-box:nth-child(6):hover {
  background: #000;
}

.work-ex-box:nth-child(1):hover img, .work-ex-box:nth-child(2):hover img, .work-ex-box:nth-child(3):hover img, .work-ex-box:nth-child(4):hover img, .work-ex-box:nth-child(5):hover img, .work-ex-box:nth-child(6):hover img {
  filter: invert();
}

.work-ex-box:nth-child(1):hover:after, .work-ex-box:nth-child(2):hover:after, .work-ex-box:nth-child(3):hover:after, .work-ex-box:nth-child(4):hover:after, .work-ex-box:nth-child(5):hover:after, .work-ex-box:nth-child(6):hover:after {
  outline: 2px solid #fff;
}

.skills {
  grid-column: 2 / 14;
  padding: 6rem;
}

.skills-box__wrapper {
  grid-gap: 4rem;
  grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
  margin-top: 6rem;
  display: grid;
}

.skills-box-skill {
  height: 6rem;
  border: 1px solid #797575;
  border-bottom: 4px solid #0ff;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 2rem 1rem;
  display: flex;
}

.skills-box-skill a {
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
  display: flex;
}

.skills-box-skill h4 {
  text-transform: capitalize;
  color: #797575;
  flex-direction: column;
  justify-content: center;
  font-family: AntonioLight, sans-serif;
  font-size: 2rem;
  text-decoration: none;
  transition: color .3s ease-in-out;
  display: flex;
}

.skills-box-skill img {
  height: 2rem;
}

.skills-box-skill:hover h4 {
  color: #fff;
}

.skills-box-skill:hover img {
  height: 2rem;
  filter: invert() saturate(100);
}

/*# sourceMappingURL=index.abf7c13f.css.map */
